<template>
  <Dialog
    v-model:visible="visibleDialog"
    header="CARTÃO DE ACESSO"
    :modal="true"
    @hide="hideDialog"
    class="p-fluid"
  >
    <div class="p-d-flex p-ai-center p-jc-center">
      <Card id="cartaoQRCODE" style="width: 22.5em">
        <template #header>
          <div style="padding: 55px 55px 20px 55px; text-align: center">
            <img
              src="../../../../public/images/sespa/logoEstado.svg"
              alt="SESPA"
              style="display: block; margin: 0 auto"
            />
          </div>
          <div class="p-d-flex p-ai-center p-jc-center">
            <div style="padding: 10px; text-align: center">
              <!-- Verifica se o person.key existe -->
              <qrcode-vue
                v-if="person && person.key"
                class="img-fluid img-thumbnail"
                :value="qrCode"
                :size="size"
                level="H"
              />
              <!-- Exibe a mensagem personalizada caso o QR Code não esteja disponível -->
              <b v-else>
                Seu usuário não possui atualmente um cartão atribuído, verifique
                com o setor responsável para atualizar suas informações.
              </b>
            </div>
          </div>
        </template>

        <template #title>
          <div class="p-text-center" style="font-size: large">
            <span v-if="person">{{ person.name }}</span>
          </div>
        </template>

        <template #subtitle>
          <div v-if="person && person.key" class="p-text-center">
            USO PESSOAL E INTRANSFERÍVEL.<br />
            ESTE CARTÃO É DE USO EXCLUSIVO.<br />
            PROIBIDO COMPARTILHAMENTO.
          </div>
        </template>
      </Card>
    </div>

    <template #footer>
      <Button
        label="Baixar"
        icon="pi pi-download"
        severity="warn"
        @click="downloadCardAsImage"
        v-if="person && person.key"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
</template>
  
  
  <script>
// Importando biblioteca de QR Code e html2canvas
import QrcodeVue from "qrcode.vue";
import html2canvas from "html2canvas";
import PersonService from "../../../service/persons/person_service";

export default {
  components: {
    QrcodeVue,
  },
  data() {
    return {
      qrCode: null,
      person: null,
      personService: new PersonService(),
      size: 150,
    };
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.users.dialogQrCode;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.users.dialogQrCode = value;
      },
    },
  },
  methods: {
    hideDialog() {
      this.visibleDialog = false;
      this.qrCode = null;
    },

    // Função para buscar os dados da pessoa e o QR Code
    getData() {
      this.personService
        .findById(this.$Token.jwtDecode().personId)
        .then((data) => {
          this.person = data;
          this.qrCode = `${this.person.key}`;
        });
    },

    downloadCardAsImage() {
      const cardElement = document.getElementById("cartaoQRCODE");

      // Usando html2canvas para capturar o conteúdo do elemento
      html2canvas(cardElement, {
        scale: 2, // Aumenta a qualidade da imagem
        useCORS: true, // Habilita cross-origin para imagens externas
      }).then((canvas) => {
        // Define o nome do arquivo concatenando com o person.name
        const link = document.createElement("a");
        const fileName = this.person.name
          ? `cartao_acesso_${this.person.name}.png`
          : "cartao_acesso.png";

        // Cria uma nova imagem com dimensões exatas menores
        const imgWidth = 300; // Largura em pixels
        const imgHeight = 480; // Altura em pixels

        // Ajusta a escala para o canvas
        const resizedCanvas = document.createElement("canvas");
        resizedCanvas.width = imgWidth;
        resizedCanvas.height = imgHeight;

        const ctx = resizedCanvas.getContext("2d");
        ctx.drawImage(canvas, 0, 0, imgWidth, imgHeight);

        link.href = resizedCanvas.toDataURL("image/png");
        link.download = fileName; // Define o nome do arquivo com person.name
        link.click();
      });
    },
  },
};
</script>
  
  
  <style scoped>
/* Centralize text and images within the Card */
#cartaoQRCODE {
  text-align: center;
}
</style>
  