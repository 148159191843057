<template>
  <AppConfig />
  <Toast />
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar v-if="isAuthenticated" @menu-toggle="onMenuToggle" />
    <div
      class="layout-sidebar"
      v-if="isAuthenticated"
      @click="onSidebarClick"
      :class="{ 'hide-menu': this.$store.state.views.users.dialogChangeBond }"
    >
      <TokenTimer @tokenExpired="onTokenExpired" />
      <AppMenu
        :model="menu"
        :local="local"
        @menuitem-click="onMenuItemClick"
        :key="menuKey"
        class="menu-custom"
      />
    </div>
    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>
    <transition name="layout-mask">
      <div
        class="layout-mask p-component-overlay"
        v-if="mobileMenuActive && isAuthenticated"
      ></div>
    </transition>
    <dialog-change-password :user="user" />
    <DialogTerm :userSelected="userSelect" />
    <DialogGeneratedQrCode />
    <dialog-change-bond
      :bondsPerson="bonds"
      v-if="this.$store.state.views.users.dialogChangeBond && isAuthenticated"
      @bondSelected="bondSelected"
      @cancel="signOut"
      @updateMenu="updateMenu"
    />
  </div>
</template>

<script>
//COMPONENTS
import AppTopBar from "./components/menu/AppTopbar.vue";
import AppMenu from "./components/menu/AppMenu.vue";
import AppFooter from "./components/menu/AppFooter.vue";
import AppConfig from "./components/menu/AppConfig.vue";
import DialogChangeBond from "./pages/users/components/dialog-change-bond.vue";
import TokenTimer from "./components/menu/TokenTimer.vue";
import DialogGeneratedQrCode from "./pages/main/components/DialogGeneratedQrCode.vue";
import DialogTerm from "./pages/main/components/DialogAcceptTerm.vue";

//SERVICES
import BondService from "./service/bond/bond_service";
import AuthService from "./service/auth/auth_service";
import UserService from "./service/user/user_service";

export default {
  emits: ["change-theme"],
  data() {
    return {
      showDialogChangeBond: false,
      isLoggingOut: false, // Variável de controle
      user: null,
      userSelect: null,
      local: null,
      expired: false,
      selectedBondLocalId: null,
      bonds: [],
      userService: new UserService(),
      bondService: new BondService(),
      authService: new AuthService(),
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: "Principal",
          items: [
            {
              label: "Home",
              icon: "pi pi-fw pi-home",
              to: "/",
            },
            {
              label: "Acessar QRCode",
              icon: "pi pi-qrcode",
              command: () => {
                this.showQrCode();
              },
            },
          ],
        },
        {
          label: "Administração do Sistema",
          visible: () => {
            if (this.findPermission("ROLE_INTRANET_CADASTRAR_CAT_SERVICO")) {
              return true;
            } else if (this.findPermission("ROLE_INTRANET_CADASTRAR_SERVICO")) {
              return true;
            } else if (this.findPermission("ROLE_INTRANET_CADASTRAR_BANNER")) {
              return true;
            } else {
              return false;
            }
          },
          items: [
            {
              label: "Gerenciar Serviços",
              icon: "pi pi-fw pi-cog",
              to: "/services",
              visible: () => {
                return this.findPermission("ROLE_INTRANET_CADASTRAR_SERVICO");
              },
            },
            {
              label: "Gerenciar Categoria de Serviços",
              icon: "pi pi-fw pi-clone",
              to: "/category-services",
              visible: () => {
                return this.findPermission(
                  "ROLE_INTRANET_CADASTRAR_CAT_SERVICO"
                );
              },
            },
            {
              label: "Gerenciar Banners",
              icon: "pi pi-fw pi-image",
              to: "/banner",
            },
            {
              label: "Gerenciar Notificações",
              icon: "pi pi-bell",
              to: "/notifications",
            },
          ],
        },
        {
          label: "Coordenação De Pessoas",
          visible: () => {
            return (
              this.findPermission("ROLE_INTRANET_CADASTRAR_PESSOA") &&
              this.findPermission("ROLE_INTRANET_EXCLUIR_PESSOA")
            );
          },
          items: [
            {
              label: "Gerenciar Pessoas e Servidores",
              icon: "pi pi-fw pi-users",
              to: "/persons",
              // visible: () => {
              //   return (
              //     this.findPermission("ROLE_INTRANET_CADASTRAR_PESSOA") &&
              //     this.findPermission("ROLE_INTRANET_EXCLUIR_PESSOA")
              //   );
              // },
            },
            {
              label: "Gerenciar Gêneros",
              icon: "pi pi-fw pi-th-large",
              to: "/genders",
              visible: () => {
                return (
                  this.findPermission("ROLE_INTRANET_CADASTRAR_GENERO") &&
                  this.findPermission("ROLE_INTRANET_EXCLUIR_GENERO")
                );
              },
            },
            {
              label: "Cargos e Funções",
              icon: "pi pi-fw pi-book",
              visible: () => {
                return (
                  this.findPermission("ROLE_INTRANET_LISTAR_CARGOS") ||
                  this.findPermission("ROLE_INTRANET_LISTAR_FUNCOES")
                );
              },
              items: [
                {
                  label: "Gerenciar Cargos",
                  icon: "pi pi-fw pi-id-card",
                  to: "/office",
                  visible: () => {
                    return (
                      this.findPermission("ROLE_INTRANET_CADASTRAR_PESSOA") &&
                      this.findPermission("ROLE_INTRANET_EXCLUIR_PESSOA")
                    );
                  },
                },
                {
                  label: "Gerenciar Funções",
                  icon: "pi pi-fw pi-bookmark",
                  to: "/occupation",
                  visible: () => {
                    return (
                      this.findPermission("ROLE_INTRANET_CADASTRAR_FUNCAO") &&
                      this.findPermission("ROLE_INTRANET_ALTERAR_FUNCAO")
                    );
                  },
                },
              ],
            },
            {
              label: "Vínculos",
              icon: "pi pi-fw pi-list",
              visible: () => {
                return (
                  this.findPermission(
                    "ROLE_INTRANET_CADASTRAR_TIPO_AFASTAMENTO_VINCULO"
                  ) &&
                  this.findPermission(
                    "ROLE_INTRANET_EXCLUIR_TIPO_AFASTAMENTO_VINCULO"
                  )
                );
                /*
                return (
                  this.findPermission("ROLE_INTRANET_LISTAR_CARGOS") ||
                  this.findPermission("ROLE_INTRANET_LISTAR_FUNCOES") ||
                  this.findPermission(
                    "ROLE_INTRANET_LISTAR_TIPOS_AFASTAMENTOS_VINCULO"
                  ) ||
                  this.findPermission("ROLE_INTRANET_LISTAR_REGIMES_VINCULO") ||
                  this.findPermission(
                    "ROLE_INTRANET_LISTAR_SITUACOES_VINCULO"
                  ) ||
                  this.findPermission("ROLE_INTRANET_LISTAR_CATEGORIAS_VINCULO")
                );
                */
              },
              items: [
                {
                  label: "Gerenciar Categoria de Vínculo",
                  icon: "pi pi-fw pi-copy",
                  to: "/category-bond",
                  visible: () => {
                    return (
                      this.findPermission(
                        "ROLE_INTRANET_CADASTRAR_CATEGORIA_VINCULO"
                      ) &&
                      this.findPermission(
                        "ROLE_INTRANET_EXCLUIR_CATEGORIA_VINCULO"
                      )
                    );
                  },
                },
                {
                  label: "Gerenciar de Tipo Vínculo",
                  icon: "pi pi-fw pi-sitemap",
                  to: "/type-bond",
                  visible: () => {
                    return (
                      this.findPermission(
                        "ROLE_INTRANET_CADASTRAR_TIPO_VINCULO"
                      ) &&
                      this.findPermission("ROLE_INTRANET_EXCLUIR_TIPO_VINCULO")
                    );
                  },
                },
                {
                  label: "Gerenciar Situações de Vínculos",
                  icon: "pi pi-fw pi-clone",
                  to: "/situation",
                  visible: () => {
                    return (
                      this.findPermission(
                        "ROLE_INTRANET_CADASTRAR_SITUACAO_VINCULO"
                      ) &&
                      this.findPermission(
                        "ROLE_INTRANET_EXCLUIR_SITUACAO_VINCULO"
                      )
                    );
                  },
                },
                {
                  label: "Gerenciar Regimes de Vínculos",
                  icon: "pi pi-fw pi-file-o",
                  to: "/regiment",
                  visible: () => {
                    return (
                      this.findPermission(
                        "ROLE_INTRANET_CADASTRAR_REGIME_VINCULO"
                      ) &&
                      this.findPermission(
                        "ROLE_INTRANET_EXCLUIR_REGIME_VINCULO"
                      )
                    );
                  },
                },
              ],
            },

            {
              label: "Gerenciar Tipos de Afastamentos",
              icon: "pi pi-fw pi-sort-alt",
              to: "/type-removal",
              visible: () => {
                return (
                  this.findPermission(
                    "ROLE_INTRANET_CADASTRAR_TIPO_AFASTAMENTO_VINCULO"
                  ) &&
                  this.findPermission(
                    "ROLE_INTRANET_EXCLUIR_TIPO_AFASTAMENTO_VINCULO"
                  )
                );
              },
            },
          ],
        },
        {
          label: "Unidades institucionais",
          visible: () => {
            return (
              this.findPermission("ROLE_INTRANET_CADASTRAR_SETOR") ||
              this.findPermission("ROLE_INTRANET_CADASTRAR_LOCAL")
            );
          },
          items: [
            {
              label: "Gerenciamento de Setores",
              icon: "pi pi-building",
              to: "/sectors",
              visible: () => {
                return (
                  this.findPermission("ROLE_INTRANET_CADASTRAR_SETOR") &&
                  this.findPermission("ROLE_INTRANET_REMOVER_SETOR")
                );
              },
            },
            {
              label: "Gerenciamento de Locais",
              icon: "pi pi-fw pi-clone",
              to: "/locals",
              visible: () => {
                return (
                  this.findPermission("ROLE_INTRANET_CADASTRAR_LOCAL") &&
                  this.findPermission("ROLE_INTRANET_REMOVER_LOCAL")
                );
                // return (
                //   this.findPermission(
                //     "ROLE_INTRANET_CADASTRAR_TIPO_AFASTAMENTO_VINCULO"
                //   ) &&
                //   this.findPermission(
                //     "ROLE_INTRANET_EXCLUIR_TIPO_AFASTAMENTO_VINCULO"
                //   )
                // );
              },
            },
          ],
        },
        {
          label: "Administração e Usuários",
          visible: () => {
            return (
              this.findPermission("ROLE_INTRANET_CADASTRAR_USUARIO") &&
              this.findPermission("ROLE_INTRANET_EXCLUIR_USUARIO")
            );
          },
          items: [
            {
              label: "Gerenciamento de Usuários",
              icon: "pi pi-user-edit",
              to: "/users",
              visible: () => {
                return (
                  this.findPermission("ROLE_INTRANET_CADASTRAR_USUARIO") &&
                  this.findPermission("ROLE_INTRANET_EXCLUIR_USUARIO")
                );
              },
            },

            {
              label: "Gerenciamento de Grupos",
              icon: "pi pi-fw pi-list",
              to: "/groups",
              visible: () => {
                return this.findPermission("ROLE_INTRANET_CADASTRAR_GRUPO");
              },
            },
            {
              label: "Gerenciamento de Permissões",
              icon: "pi pi-fw pi-cog",
              to: "/permissions",
              visible: () => {
                return this.findPermission("ROLE_INTRANET_CADASTRAR_PERMISSAO");
              },
            },
          ],
        },
        {
          label: "Acompanhamento de Log",
          visible: () => {
            return this.findPermission("ROLE_INTRANET_LISTAR_LOGS");
          },
          items: [
            {
              label: "Log's e Registros",
              icon: "pi pi-info-circle",
              to: "/log",
              visible: () => {
                return this.findPermission("ROLE_INTRANET_LISTAR_LOGS");
              },
            },
          ],
        },
      ],
      menuKey: 0,
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
      // Verifica se a rota possui meta e adiciona a uma variavel antes de chamar checkPermission
      const routePermission = this.$route.meta?.groupPermission;

      if (routePermission) {
        // Se possui uma permissão de rota, verifica, se não possuir devolve para a tela de acesso negado.
        if (!this.checkPermission(routePermission)) {
          this.$router.push("/access-denied");
        }
      }

      //VERIFICA SE POSSUO AINDA TOKEN, SE SIM, ELE VERIFICA SE ESTÁ EXPIRADO PARA PODER PROSSEGUIR, CASO NÃO, JÁ DEVOLVE PARA A ROTA DE LOGIN E RETIRA O TOKEN
      if (sessionStorage.getItem("token")) {
        this.onTokenExpired();
      } else {
        this.$Token.clearAccessToken();
        this.$router.push("/");
      }
    },
  },
  created() {
    if (!this.isAuthenticated) {
      this.$router.push("/"); // Redireciona se não estiver autenticado
    } else {
      // Se estiver autenticado, carrega os dados
      window.addEventListener("storage", this.checkSessionStorage);
      this.$store.dispatch("setUserLogged");
      this.user = this.$store.getters.getUserLogged;
      this.loadBonds();
    }
  },
  beforeUnmount() {
    window.removeEventListener("storage", this.checkSessionStorage);
  },
  methods: {
    showQrCode() {
      this.userService.findById(this.user.id).then((data) => {
        if (!data.term) {
          this.userSelect = data;
          this.showTerm();
        } else {
          this.$store.state.views.users.dialogQrCode = true;
        }
      });
    },
    showTerm() {
      this.$store.state.views.users.dialogTerm = true;
    },
    //MÉTODO PARA VERIFICAR QUAL GRUPO OU GRUPOS DE PERMISSÕES BASEADO NO ID DO SERVICE DE PERMISSÃO O USUARIO POSSUI EM SEU TOKEN
    checkPermission(param) {
      return param.some((param) => this.user.authorities.includes(param));
    },
    checkSessionStorage(event) {
      const token = sessionStorage.getItem("token");
      const local = parseInt(sessionStorage.getItem("local"));
      const localsToken = this.$Token.jwtDecode().local;
      if (
        (event.key === "token" && !event.newValue) ||
        (event.key === "local" && !event.newValue) ||
        !token ||
        !local ||
        !localsToken.some((localItem) => localItem.id === parseInt(local))
      ) {
        this.signOut();
        return;
      }
      this.local = local;
      this.loadBonds();
    },

    loadBonds() {
      const selectLocal = parseInt(sessionStorage.getItem("local"));
      if (!selectLocal) {
        this.$store.state.views.users.dialogChangeBond = true;
        this.bondService
          .findByPersonId(this.$Token.jwtDecode().personId)
          .then((data) => {
            this.bonds = data;
          });
      }
    },
    bondSelected(localId) {
      sessionStorage.setItem("local", localId);
      this.$store.state.views.users.dialogChangeBond = false; // Fechar a caixa de diálogo após selecionar um vínculo
      this.loadBonds();
    },
    signOut() {
      if (!this.isLoggingOut) {
        this.isLoggingOut = true; // Define que está em processo de logout
        this.$store.state.views.users.dialogQrCode = false;
        this.authService
          .logout()
          .then(() => {
            this.$router.push("/").finally(() => {
              this.isLoggingOut = false; // Resetar a variável após o redirecionamento
            });
          })
          .catch(() => {
            this.$router.push("/").finally(() => {
              this.isLoggingOut = false; // Resetar a variável após o redirecionamento
            });
          });
      }
    },
    findPermission(param) {
      if (this.user.authorities) {
        let isPermited = this.user.authorities.filter((a) => a == param);
        return isPermited.length > 0;
      } else {
        return false;
      }
    },
    onTokenExpired() {
      this.$Token
        .checkToken()
        .then(() => {
          // Token válido, continuar com a lógica do aplicativo
        })
        .catch(() => {
          // Token inválido ou erro, acionar o signOut
          this.signOut();
        });
    },
    //METODO PARA FORÇAR A ATUALIZAÇÃO DO COMPONENTE DO APPMENU POIS AO SELECIONAR A DIALOG, O MESMO NÃO ALTERAVA CORRETAMENTE
    updateMenu() {
      this.menuKey++; // Incrementa a chave para forçar a atualização do AppMenu
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;
      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
  },
  computed: {
    isAuthenticated() {
      return !!sessionStorage.getItem("token");
    },
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppFooter: AppFooter,
    AppConfig: AppConfig,
    DialogChangeBond,
    TokenTimer,
    DialogGeneratedQrCode,
    DialogTerm,
  },
};
</script>

<style lang="scss">
@import "./App.scss";

.hide-menu {
  display: none !important;
}
</style>
