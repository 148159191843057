<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '1100px' }"
    :modal="true"
    @hide="hideDialog"
    class="p-fluid"
    :closable="false"
  >
    <div
      ref="scrollableContent"
      @scroll="checkScrollEnd"
      style="max-height: 600px; overflow-y: auto; padding-right: 10px;"
    >
      <p style="text-align: center">
        <span style="font-size: 14pt">
          <Strong>TERMO DE RESPONSABILIDADE</Strong>
        </span>
        <br />
        <span style="font-size: 14pt"
          >Para utilização de QR Code no acesso às dependências da SESPA</span
        >
      </p>
      <p>
        <span>
          Eu, <strong>{{ userSelected.person.name }}</strong
          >, portador(a) do CPF nº
          <strong>{{ userSelected.person.cpf }}</strong> e matrícula funcional
          nº <strong>{{ userSelected.person.credential }}</strong
          >, servidor(a) público(a) vinculado(a) à Secretaria de Estado de Saúde
          Pública (SESPA), <Strong>DECLARO</Strong> estar ciente das condições e
          responsabilidades relacionadas à utilização do QR Code de acesso às
          dependências da SESPA, conforme as disposições legais aplicáveis,
          especialmente as previstas no Regime Jurídico Único dos Servidores
          Públicos Civis do Estado do Pará (Lei Estadual 5.810/94) e demais
          legislações correlatas.
        </span>
      </p>
      <br />

      <p style="text-align: center">
        <span style="font-size: 14pt">
          <Strong>CLÁUSULAS</Strong>
        </span>
        <br />
        <br />
        <span style="font-size: 10pt">
          <Strong style="font-size: 14pt">Cláusula 1ª</Strong>
        </span>
        <br />
        <span style="font-size: 14pt">Responsabilidade pelo QR Code</span>
      </p>

      <p>
        O QR Code fornecido ao servidor é de uso pessoal, intransferível e
        exclusivo, devendo ser utilizado apenas para o controle de acesso às
        dependências da SESPA. O servidor assume total responsabilidade pela
        guarda e uso adequado do QR Code, ficando proibida a cessão, empréstimo
        ou compartilhamento a terceiros, sob pena de responsabilidade
        administrativa, cível e penal.
      </p>

      <p style="text-align: center">
        <span style="font-size: 10pt">
          <Strong style="font-size: 14pt">Cláusula 2ª</Strong>
        </span>
        <br />
        <span>Deveres do Servidor</span>
      </p>

      <div>
        O servidor se compromete a utilizar o QR Code conforme as diretrizes
        estabelecidas pela SESPA, observando os deveres funcionais previstos na
        Lei Estadual 5.810/94, que prevê, entre outros:
        <ol type="a">
          <li>
            O dever de lealdade à instituição e de zelo pela segurança do
            patrimônio público;
          </li>
          <li>
            O dever de observar as normas de conduta e integridade, incluindo a
            utilização correta de sistemas e dispositivos de segurança.
          </li>
        </ol>
      </div>

      <p style="text-align: center">
        <span style="font-size: 10pt">
          <Strong style="font-size: 14pt">Cláusula 3ª</Strong>
        </span>
        <br />
        <span>Sanções pelo Uso Indevido</span>
      </p>

      <div>
        O uso indevido, compartilhamento ou transferência do QR Code pode
        acarretar sanções administrativas, cíveis e penais, conforme previsto no
        RJU e na legislação correlata, tais como:
        <ol type="a">
          <li>Lei Estadual 5.810/94: Advertência, suspensão e demissão;</li>
          <li>
            Lei Federal 12.527/11 (Lei de Acesso à Informação), no que tange à
            violação de dados sensíveis e restritos;
          </li>
          <li>
            Lei Federal 13.709/18 (Lei Geral de Proteção de Dados Pessoais -
            LGPD), que regula o tratamento de dados pessoais, impondo
            penalidades para condutas inadequadas que envolvam a segurança de
            informações pessoais ou institucionais.
          </li>
        </ol>
      </div>

      <p style="text-align: center">
        <span style="font-size: 10pt">
          <Strong style="font-size: 14pt">Cláusula 4ª</Strong>
        </span>
        <br />
        <span>Perda, Roubo ou Extravio</span>
      </p>

      <p>
        Em caso de perda, roubo ou extravio do dispositivo contendo o QR Code, o
        servidor deve notificar imediatamente o setor competente da SESPA, para
        que sejam adotadas as providências de bloqueio do acesso e a emissão de
        um novo QR Code, sob pena de responsabilidade do servidor na hipótese de
        acesso indevido às dependências da SESPA decorrente do não cumprimento
        do dever de comunicação aqui previsto.
      </p>

      <p style="text-align: center">
        <span style="font-size: 10pt">
          <Strong style="font-size: 14pt">Cláusula 5ª</Strong>
        </span>
        <br />
        <span>Confidencialidade e Segurança</span>
      </p>

      <p>
        O servidor compromete-se a seguir as normas de confidencialidade e
        segurança definidas pela SESPA, assegurando que o uso do QR Code seja
        restrito às finalidades estabelecidas, cientificando-se que o
        descumprimento dessas regras poderá acarretar sanções legais expostas
        acima.
      </p>

      <p style="text-align: center">
        <span style="font-size: 10pt">
          <Strong style="font-size: 14pt">Cláusula 6ª</Strong>
        </span>
        <br />
        <span>Validade e Alterações</span>
      </p>

      <p>
        Este termo de responsabilidade permanecerá válido enquanto o servidor
        estiver vinculado à SESPA ou até a implementação de um novo sistema de
        controle de acesso, podendo a SESPA revisar as suas condições de uso
        poderá a qualquer tempo, mediante notificação prévia aos servidores.
      </p>

      <p style="text-align: center">
        <span style="font-size: 10pt">
          <Strong style="font-size: 14pt">Cláusula 7ª</Strong>
        </span>
        <br />
        <span>Declaração de Concordância</span>
      </p>

      <p>
        <Strong>DECLARO</Strong> estar ciente de todas as disposições aqui
        contidas, comprometendo-me a utilizar o QR Code em conformidade com
        diretrizes definidas neste termo, assumindo total responsabilidade pelo
        seu uso adequado e <Strong>ESTOU CIENTE</Strong> das implicações legais
        decorrentes do uso inadequado ou da transferência indevida do QR Code a
        terceiros.
      </p>
    </div>

    <template #footer>
      <Button
        v-if="scrollEndReached"
        label="Aceitar"
        class="p-button"
        icon="pi pi-check"
        @click="acceptTerm(this.userSelected)"
      />
      <Button
        label="Recusar"
        icon="pi pi-times"
        class="p-button-text"
        @click="hideDialog()"
      />
    </template>
  </Dialog>
</template>
  
  <script>
// Services
import UserService from "../../../service/user/user_service";

export default {
  props: ["userSelected"],
  setup() {},
  data() {
    return {
      userService: new UserService(),
      scrollEndReached: false,
    };
  },
  mounted() {
    this.scrollEndReached = false; // Inicialmente, o botão estará escondido
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.users.dialogTerm;
        return value;
      },
      set(value) {
        this.$store.state.views.users.dialogTerm = value;
      },
    },
  },
  methods: {
    acceptTerm(user) {
      user.term = true;
      this.userService
        .acceptTerm(user)
        .then((data) => {
          this.$msgSuccess(data);
          this.hideDialog();
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    hideDialog() {
      this.submitted = false;
      this.visibleDialog = false;
      this.scrollEndReached = false;
    },
    //METODO PARA CHECAR SE O USUARIO ROLOU ATÉ O FINAL DA PAGINA DO TERMO
    checkScrollEnd() {
      const el = this.$refs.scrollableContent;
      // Verifica se o usuário atingiu o final da barra de rolagem com uma pequena margem de erro
      const atBottom = el.scrollTop + el.clientHeight >= el.scrollHeight - 10; // margem de 10px
      if (atBottom) {
        this.scrollEndReached = true;
      } else {
        this.scrollEndReached = false;
      }
    },
  },
};
</script>
  
  <style scoped>
p,
div,
span {
  font-size: 18px;
  text-align: justify;
}
</style>