import axios from "axios";
import Token from "../../utilities/jwt/Token.js";

export default class AuthService extends Token {

  constructor(endpoint) {
    super();
    this.endpoint = endpoint;
  }

  findAll() {
    return axios({
      method: "get",
      url: this.api_url + this.endpoint,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    }).then((res) => res.data);
  }

  findById(id) {
    return axios({
      method: "get",
      url: this.api_url + this.endpoint + "/" + id,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    }).then((res) => res.data);
  }

  create(data) {
    return axios({
      method: "post",
      url: this.api_url + this.endpoint,
      withCredentials: true,
      data: data,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    });
  }

  update(data) {
    return axios({
      method: "put",
      url: this.api_url + this.endpoint + "/" + data.id,
      withCredentials: true,
      data: data,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    });
  }

  delete(id) {
    return axios({
      method: "delete",
      url: this.api_url + this.endpoint + "/" + id,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    });
  }

  login(login, password) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: this._api_url + "oauth/token",
        withCredentials: true,
        data:
          "username=" +
          login +
          "&password=" +
          password +
          "&grant_type=password",
        headers: {
          Authorization: "Basic c2VzcGF3ZWI6IyQzJHBAY3QxJCM=",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          new Token().storeToken(res.data);
          resolve();
        })
        .catch((response) => {
          // const jsonStr = JSON.stringify(err);
          // const jsonError = JSON.parse(jsonStr);
          reject(response);
        });
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      let token = sessionStorage.getItem("token");
      token = JSON.parse(token);
      axios({
        method: "delete",
        url: this._api_url + "tokens/revoke",
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + token.access_token,
        },
      })
        .then(() => {
          new Token().clearAccessToken();
          resolve();
        })
        .catch((err) =>
          reject(err));

    });

  }

  refresh_token() {
    return axios({
      method: "post",
      url: this._api_url + "oauth/token",
      withCredentials: true,
      data: "grant_type=refresh_token",
      headers: {
        Authorization: "Basic c2VzcGF3ZWI6IyQzJHBAY3QxJCM=",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

}
