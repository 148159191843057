<template>
  <div class="layout-menu-container">
    <hr />
    <div v-if="user.person.identifierFile" align="center">
      <img
        :src="$Storage.previewImg(user.person.identifierFile)"
        width="140"
        height="140"
        class="img-fluid img-thumbnail"
      />
    </div>
    <div v-else align="center">
      <img
        src="../../../public/images/sespa/brasao.svg"
        width="140"
        height="140"
        class="img-fluid img-thumbnail"
      />
    </div>
    <hr />
    <div align="center" v-if="user && user.person">
      <small>
        <b>
          {{ user.person.name }} <br />
          {{ user.person.credential }} <br />
          {{ grupo }}<br />
          {{ local }}<br />
        </b>
      </small>
    </div>
    <hr />
    <!-- Campo de busca para filtrar os itens do menu -->
    <div class="search-container mb-3">
      <span class="p-input-icon-left">
        <i class="pi pi-search" />
        <InputText
          v-model="menuFilter"
          @input="filterMenu"
          placeholder="Pesquisar no menu..."
          class="search-input"
        />
      </span>
    </div>
    <!-- Renderização dos itens filtrados -->
    <AppSubmenu
      :items="filteredModel"
      class="layout-menu"
      :root="true"
      @menuitem-click="onMenuItemClick"
      @keydown="onKeyDown"
    />
  </div>
</template>

<script>
import AppSubmenu from "./AppSubmenu";
import User from "../../models/users";
import UserService from "../../service/user/user_service";
import LocalService from "../../service/local/local";

export default {
  props: {
    model: Array,
  },
  data() {
    return {
      userService: new UserService(),
      localService: new LocalService(),
      user: new User(),
      local: null,
      grupo: null,
      menuFilter: "", // Campo de filtro do menu
      filteredModel: this.model, // Itens filtrados do menu
    };
  },

  methods: {
    onMenuItemClick(event) {
      this.$emit("menuitem-click", event);
    },
    onKeyDown(event) {
      const nodeElement = event.target;
      if (event.code === "Enter" || event.code === "Space") {
        nodeElement.click();
        event.preventDefault();
      }
    },
    loadUserData() {
      this.userService.findById(this.usuario.id).then((data) => {
        this.user = data;
      });
      this.$Token.jwtDecode().services.forEach((element) => {
        if (element.service === 2) {
          this.grupo = element.groupPermission.replace(/_/g, " ");
        }
      });

      if (sessionStorage.getItem("local")) {
        this.localService
          .findById(parseInt(sessionStorage.getItem("local")))
          .then((data) => {
            this.local = data.name;
          });
      }
    },
    // Função de filtro do menu
    filterMenu() {
      const filter = this.menuFilter
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();

      // Função auxiliar para verificar se o item ou qualquer subitem corresponde ao filtro
      const matchesFilter = (item) => {
        // Normaliza o label do item atual
        const normalizedLabel = item.label
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();

        // Verifica se o item atual corresponde ao filtro
        if (normalizedLabel.includes(filter)) {
          return true;
        }

        // Se o item tiver subitens, verifica cada um deles
        if (item.items && item.items.length) {
          return item.items.some(matchesFilter);
        }

        return false; // Retorna falso se não houver correspondência
      };

      // Filtra os itens principais com base no filtro
      this.filteredModel = this.model.filter(matchesFilter);
    },
  },
  computed: {
    usuario: {
      get() {
        return this.$store.getters.getUserLogged;
      },
    },
  },
  watch: {
    local() {
      this.loadUserData();
    },
    model: {
      immediate: true,
      handler() {
        this.filteredModel = this.model; // Atualiza o modelo filtrado quando o model mudar
      },
    },
  },
  mounted() {
    this.loadUserData();
  },
  components: {
    AppSubmenu: AppSubmenu,
  },
};
</script>
<style scoped>
.search-container {
  width: 100%; /* Faz a div do campo de busca ocupar 100% da largura da div pai */
}

.search-input {
  width: 100%; /* Faz o input ocupar 100% da largura da div de busca */
  box-sizing: border-box; /* Garante que o padding não afete a largura total */
}

/* Opcional: para garantir que a div de busca tenha um pouco de margem */
.mb-3 {
  margin-bottom: 16px; /* Ajuste conforme necessário */
}
</style>
