<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '90vw', maxWidth: '600px' }"
    header="Selecione o seu vínculo"
    :modal="true"
    @hide="hideDialog"
    class="p-fluid"
    :closable="false"
  >
    <div class="p-grid">
      <div
        class="col-12 clickable-card"
        v-for="bond in filteredBonds"
        :key="bond.id"
        @click="selectBond(bond)"
      >
        <Card>
          <template #title>
            <div v-if="bond.numberBond != 0">
              Informações do Vínculo Nº {{ bond.numberBond }}
            </div>
            <div v-else>Informações do Vínculo</div>
          </template>
          <template #content>
            <p><strong>Cargo:</strong> {{ bond.office.description }}</p>
            <p><strong>Função:</strong> {{ bond.occupation.description }}</p>
            <p>
              <strong>Setor de Atuação:</strong> {{ bond.acting.name }} ({{
                bond.acting.acronym
              }})
            </p>
            <p>
              <strong>Local de Atuação:</strong> {{ bond.acting.local.name }}
            </p>
            <p>
              <strong>Setor de Lotação:</strong> {{ bond.capacity.name }} ({{
                bond.capacity.acronym
              }})
            </p>
          </template>
        </Card>
      </div>
    </div>
    <template #footer>
      <Button label="Cancelar" severity="secondary" @click="cancel" />
    </template>
  </Dialog>
</template>

<script>
export default {
  props: ["bondsPerson"],
  data() {
    return {
      visibleDialog: false,
    };
  },
  computed: {
    filteredBonds() {
      if (!this.bondsPerson) return [];
      return this.bondsPerson.filter((bond) =>
        this.validConditionActiveBond(bond.situation.id)
      );
    },
  },
  methods: {
    showDialog() {
      this.visibleDialog = true;
    },
    hideDialog() {
      this.visibleDialog = false;
    },
    selectBond(bond) {
      //emitir o evento bondSelected ao clicar no vinculo
      this.$emit("bondSelected", bond.acting.local.id);
      this.$emit("updateMenu");
      this.hideDialog();
      this.$router.push("/system");
    },
    cancel() {
      this.hideDialog();
      // Emitir o evento 'cancel' ao clicar no botão Cancelar
      this.$emit("cancel");
    },
    validConditionActiveBond(id) {
      return id === 1 || id === 3 || id === 4;
    },
  },
  watch: {
    bondsPerson: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.showDialog();
        }
      },
    },
  },
};
</script>

<style scoped>
.p-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.clickable-card {
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.clickable-card:hover {
  transform: scale(1.02);
  border-radius: 15px;
}

.p-card {
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  overflow: hidden;
}

.p-card-content p {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  line-height: 1.4;
}
</style>
