import { createRouter, createWebHashHistory } from "vue-router";
import App from "./App.vue";
const routes = [
  {
    path: "/system",
    name: "app",
    component: App,
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("./pages/main/Home.vue"),
      },
      {
        path: "/services",
        name: "services",
        component: () => import("./pages/services/ServiceView.vue"),
        meta: { groupPermission: ["ROLE_INTRANET_EXCLUIR_SERVICO"] },
      },
      {
        path: "/category-services",
        name: "category-services",
        component: () =>
          import("./pages/category_service/CategoryServiceView.vue"),
        meta: { groupPermission: ["ROLE_INTRANET_EXCLUIR_SERVICO"] },
      },
      {
        path: "/banner",
        name: "banner",
        component: () => import("./pages/banners/BannerView.vue"),
        meta: { groupPermission: ["ROLE_INTRANET_EXCLUIR_SERVICO"] },
      },
      {
        path: "/persons",
        name: "persons",
        component: () => import("./pages/persons/PersonView.vue"),
        meta: { groupPermission: ["ROLE_INTRANET_CADASTRAR_PESSOA", "ROLE_INTRANET_EXCLUIR_PESSOA", "ROLE_INTRANET_LISTAR_PESSOAS_NOME_CPF_MATRICULA"] },
      },
      {
        path: "/genders",
        name: "genders",
        component: () => import("./pages/genders/GenderView.vue"),
        meta: { groupPermission: ["ROLE_INTRANET_EXCLUIR_SERVICO"] },
      },
      {
        path: "/locals",
        name: "locals",
        component: () => import("./pages/local/LocalView.vue"),
        meta: { groupPermission: ["ROLE_INTRANET_EXCLUIR_SERVICO"] },
      },
      {
        path: "/sectors",
        name: "sectors",
        component: () => import("./pages/sector/SectorView.vue"),
        meta: { groupPermission: ["ROLE_INTRANET_CADASTRAR_SETOR", "ROLE_INTRANET_REMOVER_SETOR"] },
      },
      {
        path: "/permissions",
        name: "permissions",
        component: () => import("./pages/permission/PermissionView.vue"),
        meta: { groupPermission: ["ROLE_INTRANET_EXCLUIR_SERVICO"] },
      },
      {
        path: "/users",
        name: "users",
        component: () => import("./pages/users/UsersView.vue"),
        meta: { groupPermission: ["ROLE_INTRANET_LISTAR_USUARIOS"] },
      },
      {
        path: "/groups",
        name: "groups",
        component: () => import("./pages/groups/GroupsView.vue"),
        meta: { groupPermission: ["ROLE_INTRANET_EXCLUIR_SERVICO"] },
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("./pages/profile/ProfileView.vue"),
        meta: { groupPermission: ["ROLE_INTRANET_ALTERAR_PESSOA"] },
      },
      {
        path: "/category-bond",
        name: "category-bond",
        component: () => import("./pages/category_bond/CategoryBondView.vue"),
        meta: { groupPermission: ["ROLE_INTRANET_EXCLUIR_SERVICO"] },
      },
      {
        path: "/type-bond",
        name: "type-bond",
        component: () => import("./pages/type_bond/TypeBondView.vue"),
        meta: { groupPermission: ["ROLE_INTRANET_EXCLUIR_SERVICO"] },
      },
      {
        path: "/type-removal",
        name: "type-removal",
        component: () => import("./pages/type_removal/TypeRemovalView.vue"),
        meta: { groupPermission: ["ROLE_INTRANET_EXCLUIR_SERVICO"] },
      },
      {
        path: "/regiment",
        name: "regiment",
        component: () => import("./pages/regiment/RegimentView.vue"),
        meta: { groupPermission: ["ROLE_INTRANET_EXCLUIR_SERVICO"] },
      },
      {
        path: "/situation",
        name: "situation",
        component: () => import("./pages/situation/SituationView.vue"),
        meta: { groupPermission: ["ROLE_INTRANET_EXCLUIR_SERVICO"] },
      },
      {
        path: "/office",
        name: "office",
        component: () => import("./pages/office/OfficeView.vue"),
        meta: { groupPermission: ["ROLE_INTRANET_CADASTRAR_CARGO"] },
      },
      {
        path: "/occupation",
        name: "occupation",
        component: () => import("./pages/occupation/OccupationView.vue"),
        meta: { groupPermission: ["ROLE_INTRANET_CADASTRAR_FUNCAO"] },
      },
      {
        path: "/log",
        name: "log",
        component: () => import("./pages/log/LogView.vue"),
        meta: { groupPermission: ["ROLE_INTRANET_EXCLUIR_SERVICO"] },
      },
      {
        path: "/notifications",
        name: "notifications",
        component: () => import("./pages/notifications/NotificationsView.vue"),
        meta: { groupPermission: ["ROLE_INTRANET_EXCLUIR_SERVICO"] },
      },
    ],
  },
  {
    path: "/",
    name: "login",
    component: () => import("./pages/main/Login.vue"),
  },
  {
    path: "/recoverPassword",
    name: "recoverPassword",
    component: () => import("./pages/users/UserRecoverPasswordView.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "not-found",
    component: () => import("./pages/not_found/NotFound.vue"),
  },
  {
    path: "/access-denied",
    name: "access-denied",
    component: () => import("./pages/not_found/AccessDenied.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  const local = sessionStorage.getItem('local');
  const token = sessionStorage.getItem('token');
  
  // Verifica se não há "local" ou "token" e a rota não é "login", "recoverPassword" ou "not-found"
  if ((!local || !token) && to.name !== 'login' && to.name !== 'recoverPassword' && to.name !== 'not-found') {
    if (to.name !== 'app') {
      next({ name: 'app' }); // Redireciona para a página principal
    } else {
      next(); // Permite a navegação para a página principal
    }
  } else {
    next(); // Permite a navegação
  }
});



export default router;