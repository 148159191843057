<template>
  <div>
    <AppConfig v-model:configuratorVisible="configuratorVisible" />
    <div :class="['layout-topbar', topbarClass]">
      <router-link to="/" class="layout-topbar-logo">
        <img alt="Logo" src="../../../public/images/sespa/brasao.svg" />
        <h4 class="mb-4 ml-4">INTRANET</h4>
      </router-link>
      <button
        class="p-link layout-menu-button layout-topbar-button"
        @click="onMenuToggle"
      >
        <i class="pi pi-bars"></i>
      </button>
      <button
        class="p-link layout-topbar-menu-button layout-topbar-button"
        v-styleclass="{
          selector: '@next',
          enterClass: 'hidden',
          enterActiveClass: 'scalein',
          leaveToClass: 'hidden',
          leaveActiveClass: 'fadeout',
          hideOnOutsideClick: true,
        }"
      >
        <i class="pi pi-ellipsis-v"></i>
      </button>

      {{ environmentLabel }}
      <ul class="layout-topbar-menu hidden lg:flex origin-top">
        <li>
          <button
            class="p-link layout-topbar-button"
            v-tooltip="'CONFIGURAÇÕES DE ACESSIBILIDADE'"
            @click="toggleConfigurator"
          >
            <i class="pi pi-cog"></i>
            <span>Acessibilidade</span>
          </button>
        </li>
        <li>
          <button
            class="p-link layout-topbar-button"
            @click="changePassword"
            v-tooltip.bottom="'ALTERAR SENHA'"
          >
            <i class="pi pi-lock"></i>
            <span>Alterar Senha</span>
          </button>
        </li>
        <li>
          <button class="p-link layout-topbar-button" @click="toProfile">
            <i class="pi pi-user"></i>
            <span>Perfil</span>
          </button>
        </li>
        <li>
          <button class="p-link layout-topbar-button" @click="signOut">
            <i class="pi pi-sign-out"></i>
            <span>Sair</span>
          </button>
        </li>
      </ul>
    </div>

    <dialog-change-password :user="this.$store.getters.getUserLogged" />
  </div>
</template>

<script>
//SERVICES
import AuthService from "../../service/auth/auth_service";

//COMPONENTES
import DialogChangePassword from "@/pages/users/components/dialog-change-password";
import AppConfig from "../menu/AppConfig.vue";

export default {
  components: {
    DialogChangePassword,
    AppConfig,
  },
  data() {
    return {
      authService: new AuthService(),
      configuratorVisible: false,
    };
  },
  computed: {
    topbarClass() {
      switch (process.env.VUE_APP_ENV) {
        case "development":
          return "topbar-development";
        case "staging":
          return "topbar-staging";
        case "training":
          return "topbar-training";
        default:
          return "";
      }
    },
    environmentLabel() {
      switch (process.env.VUE_APP_ENV) {
        case "development":
          return "AMBIENTE DE DESENVOLVIMENTO";
        case "staging":
          return "AMBIENTE DE HOMOLOGAÇÃO";
        case "training":
          return "AMBIENTE DE TREINAMENTO";
        default:
          return "";
      }
    },
  },
  created() {
    if (sessionStorage.getItem("token")) {
      this.$router.push("/system");
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    toggleConfigurator() {
      this.configuratorVisible = !this.configuratorVisible;
    },
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    onTopbarMenuToggle(event) {
      this.$emit("topbar-menu-toggle", event);
    },
    signOut() {
      this.authService
        .logout()
        .then(() => {
          this.$router.push("/");
        })
        .catch(() => {
          this.$router.push("/");
        });
    },
    toProfile() {
      this.$router.push("/profile");
    },
    changePassword() {
      this.$store.state.views.users.dialogChangePassword = true;
    },
  },
};
</script>

<style scoped>
.layout-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  height: 60px;
}

.environment-label {
  flex-grow: 1;
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
}

.topbar-development {
  background-color: #f8d7da; /* Vermelho claro */
  color: #721c24; /* Texto vermelho escuro */
}

.topbar-staging {
  background: #e7e439;
  /* Amarelo */
  color: #857804;
  /* Texto amarelo escuro */
}

.topbar-training {
  background-color: #73ee68; /* Verde */
  color: #197007; /* Texto Verde Escuro */
}

/* Estilo para o botão de configurações */
.layout-config-button {
  position: relative;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #333; /* Cor padrão do ícone */
  transition: color 0.3s ease;
}

.layout-config-button:hover {
  color: #007bff; /* Cor do ícone ao passar o mouse */
}
</style>
