export default class Config {

    constructor() {
        const baseUrl = process.env.VUE_APP_BASE_URL;
        const apiName = process.env.VUE_APP_API_NAME;

        // Concatena a base URL com o nome do projeto manipulado caso ele exista, senão utiliza apenas baseUrl
        this._api_url = apiName ? `${baseUrl}/${apiName}/` : `${baseUrl}/`;
        this._api_storage = `${process.env.VUE_APP_STORAGE_URL}/`;
    }

    get api_url() {
        return this._api_url;
    }

    set api_url(value) {
        this._api_url = value;
    }

    get api_storage() {
        return this._api_storage;
    }

    set api_storage(value) {
        this._api_storage = value;
    }
}