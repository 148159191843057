import jwtDecode from "jwt-decode";
import Config from "../../service/auth/config.js";
import AuthService from "../../service/auth/auth_service.js";

export default class Token extends Config {

  /** token expirado **/
  checkExpiredToken() {
    let token = sessionStorage.getItem("token");
    if (!token) return true;
    token = JSON.parse(token);
    let decodeToken = jwtDecode(token.access_token);

    let d1 = Date.now();
    let d2 = decodeToken.exp * 1000; // Multiplique por 1000 para converter segundos em milissegundos

    return d1 >= d2; // Retorna true se o token estiver expirado
  }

  /** limpar token **/
  clearAccessToken() {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("local");
    sessionStorage.removeItem("notificationNotExpiredView");
  }

  /** armazenaToken **/
  storeToken(token) {
    sessionStorage.setItem("token", JSON.stringify(token));
  }

  /** retorna token **/
  token() {
    let token = sessionStorage.getItem("token");
    token = JSON.parse(token);
    return token ? token.access_token : null;
  }

  /** decodificando jwt **/
  jwtDecode() {
    let payLoad = jwtDecode(this.token());
    return payLoad;
  }

  /** token valido **/
  checkToken() {
    return new Promise((resolve, reject) => {
      const msg = "Tempo de sessão encerrado. Para continuar efetue login novamente!";
      if (this.checkExpiredToken()) {
        // Sessão expirada
        reject(msg);
      } else {
        resolve(); // Token válido
      }
    });
  }

  /** Usuario é Admin? **/
  async checkAdmin() {
    try {
      const response = await new AuthService("user").findById(this.jwtDecode().code);
      // Verifica se existe um grupo com id === 1
      const isAdmin = response.group.some((value) => value.id === 1);
      //console.log("É admin:", isAdmin); // Exibe o resultado
      return isAdmin; // Retorna true ou false
    } catch (error) {
      //console.error("Erro ao verificar admin:", error);
      return false; // Retorna false em caso de erro
    }
  }
}
