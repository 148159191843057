import axios from "axios";
import { gerarUrl } from "../../utilities/utils/Pageable";
import AuthService from "../auth/auth_service";

export default class BondService extends AuthService {
  constructor() {
    super("bond")
  }

  findAll(queryParams) {

    queryParams = gerarUrl(queryParams);

    return axios({
      method: "get",
      url: this.api_url + "bond?" + queryParams,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    }).then((res) => res.data);
  }

  findByPersonId(personId) {
    return axios({
      method: 'get',
      url: this.api_url + 'bond/' + personId + '/person',
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }

  existBondByPersonId(personId) {
    return axios({
      method: 'get',
      url: this.api_url + 'bond/' + 'person/' + personId,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }





}
