import Token from "../jwt/Token.js";

// Função para exibir o toast
function exibirToast(severity, summary, detail) {
  this.$toast.add({
    severity,
    summary,
    detail,
    life: 6000,
  });
}

// Função para obter mensagem de erro baseada no status
function obterMensagemErro(status, mensagensDeErro) {
  return mensagensDeErro[status] || "Erro desconhecido. Contate o administrador.";
}

export default function msgErro(response) {
  const data = Array.isArray(response.response.data) ? response.response.data[0] : response.response.data;
  const status = response.response.status;

  const mensagensDeErro = {
    400: "Requisição inválida. Por favor, verifique os campos dos dados enviados.",
    401: "Não autorizado. É necessário fazer login novamente.",
    403: "Acesso negado. Você não tem permissão para acessar este recurso.",
    404: "Recurso não encontrado. Verifique o URL.",
    409: "Conflito de dados. O recurso já existe ou há um conflito na operação.",
    422: "Erro de validação. Verifique os dados enviados.",
    429: "Muitas requisições. Por favor, tente novamente mais tarde.",
    500: "Erro interno do servidor. Tente novamente mais tarde.",
    502: "Bad Gateway. O servidor recebeu uma resposta inválida.",
    503: "Serviço indisponível. Tente novamente mais tarde.",
    504: "Gateway Timeout. O servidor não respondeu a tempo."
  };

  const mensagemDesenvolvedor = data?.mensagemDesenvolvedor || "";
  const mensagemUsuario = data?.mensagemUsuario || "";

  // Se houver mensagem de usuário
  if (mensagemUsuario) {
    // Se houver mensagem de desenvolvedor e contém SQLIntegrityConstraintViolationException
    if (mensagemDesenvolvedor.includes("java.sql.SQLIntegrityConstraintViolationException")) {
      return exibirToast.call(this, "error", mensagemUsuario, "Não é possível excluir registros que possuem dados atrelados.");
    }

    // Se houver mensagem de desenvolvedor e contém NegocioException
    if (mensagemDesenvolvedor.includes("NegocioException")) {
      const negocio = mensagemDesenvolvedor.replace("br.gov.pa.saude.api.exceptionhandler.NegocioException:", "");
      return exibirToast.call(this, "error", mensagemUsuario, negocio);
    }

    // Se nenhuma das exceções específicas foi encontrada, exibe erro baseado no status
    return exibirToast.call(this, "error", mensagemUsuario, obterMensagemErro(status, mensagensDeErro));
  }

  // Se não houver mensagem de usuário, exibe erro desconhecido
  exibirToast.call(this, "error", "Alerta de Erro", "Erro desconhecido. Contate o administrador.");

  // Verificar se o token está expirado
  const token = sessionStorage.getItem("token");
  if (token && new Token().checkExpiredToken()) {
    exibirToast.call(this, "warn", "Atenção", "Sessão expirada, efetue login novamente.");
  }
}



// export default function msgErro(response) {
  
//   this.mensagemDesenvolvedor = response.response.data[0].mensagemDesenvolvedor;
//   this.mensagemUsuario = response.response.data[0].mensagemUsuario;
//   let data = response.response.data[0];
//   let status = response.response.status;

//   if (status >= 400) {
//     this.$toast.add({
//       severity: "error",
//       summary: "Alerta de Erro.",
//       detail: data.mensagemUsuario,
//       life: 6000,
//     });
//   }

//   let containing = this.mensagemDesenvolvedor.indexOf("NegocioException");
//   let foreignKey = this.mensagemDesenvolvedor.indexOf(
//     "java.sql.SQLIntegrityConstraintViolationException"
//   );
//   if (containing > -1) {
//     let negocio = this.mensagemDesenvolvedor.replace(
//       "br.gov.pa.saude.api.exceptionhandler.NegocioException:",
//       ""
//     );
//     //this.mensagemUsuario = negocio
//     this.$toast.add({
//       severity: "error",
//       summary: "Alerta de Erro.",
//       detail: negocio,
//       life: 6000,
//     });
//   }
//   if (foreignKey > -1) {
//     this.$toast.add({
//       severity: "error",
//       summary: "Alerta de Erro.",
//       detail: "Não é possivel excluir registros cujo estão dados atrelados.",
//       life: 6000,
//     });
//   }

//   if (new Token.checkExpiredToken()) {
//     let message = "Secção expirada, efetue login novamente.";
//     this.$toast.add({
//       severity: "warn",
//       summary: "Atenção.",
//       detail: message,
//       life: 6000,
//     });
//   }
// }
