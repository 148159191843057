import axios from "axios";
import Token from "../jwt/Token.js";

export default class Storage extends Token {

  /*BAIXANDO UM ARQUIVO*/
  download(hashKey) {
    return axios({
      method: "get",
      url: this.api_storage + "file/download/" + hashKey,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
      responseType: "blob"
    });
  }
  /*UPDANDO UM ARQUIVO PARA DENTRO DE UM DIRETORIO*/
  upload(arquivo, folderId) {
    return axios({
      method: "post",
      url: this.api_storage + "file/upload?folder=" + folderId,
      withCredentials: true,
      data: { file: arquivo },
      headers: {
        Authorization: "Bearer " + this.token(),
        'Content-Type': 'multipart/form-data'
      },
    });
  }
  /*ATUALIZANDO UM ARQUIVO DENTRO DE UM DIRETORIO
    PELO HASH DO ARQUIVO*/
  update(hashKey, arquivo) {
    return axios({
      method: "put",
      url: this.api_storage + "file/" + hashKey,
      withCredentials: true,
      data: { file: arquivo },
      headers: {
        Authorization: "Bearer " + this.token(),
        'Content-Type': 'multipart/form-data'
      },
    });
  }
  /*REMOVENDO ARQUIVO DE DENTRO DE UM DIRETORIO PELO HASH*/
  remove(hashKey) {
    return axios({
      method: 'delete',
      url: this.api_storage + 'file/' + hashKey,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
  /*VIZUALIZANDO UM ARQUIVO OUTRA ABA*/
  previewFileToPage(hashKey) {
    window.open(`${this.api_storage}file/preview/${hashKey}`, '_blank');
  }
  /*VIZUALIZANDO UM ARQUIVO DE ACORDO COM A URL. ESPECIFICO PARA PDF, PNG, JPG E JPEG*/
  previewImg(hashKey) {
    if (hashKey === null || hashKey === "") {
      return;
    } else {
      return this.checkProviderImg(hashKey);
    }
  }

  checkProviderImg(param) {
    /* SE O PROVEDOR DA IMAGEM FOR O FIREBASE */
    if (param.includes("firebase")) {
      return param;
      /* SE O PROVEDOR DA IMAGEM FOR API DE STORAGE */
    } else {
      return `${this.api_storage}file/preview/${param}`
    }
  }
}