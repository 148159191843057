<template>
  <div class="layout-config-wrapper">
    <!-- Botão flutuante de configuração -->
    <!-- <Button
      v-tooltip="'CONFIGURAÇÕES DE ACESSIBILIDADE'"
      class="p-button-rounded p-button-lg p-button-secondary"
      icon="pi pi-cog"
      :style="{ 'z-index': 100, width: buttonSize, height: buttonSize }"
      @click="toggleConfigurator"
    /> -->

    <!-- Sidebar de configuração -->
    <Sidebar v-model:visible="internalConfiguratorVisible" position="right">
      <div class="layout-config-content">
        <h5 class="mt-0">Configurações</h5>

        <!-- Switch para Dark Mode -->
        <h6>Modo Escuro</h6>
        <InputSwitch v-model="darkModeActive" @change="switchTheme" />

        <!-- Switch para Modo Daltônico -->
        <!-- <h6>Modo Daltônico</h6>
        <InputSwitch v-model="colorBlindModeActive" @change="switchTheme" /> -->

        <!-- Slider para tamanho da fonte -->
        <h6>Tamanho da Fonte</h6>

        <Slider
          v-model="fontSizeScale"
          :step="1"
          :min="50"
          :max="200"
          class="w-14rem"
          @change="changeFontSize"
        />
      </div>
    </Sidebar>
  </div>
</template>

<script>
export default {
  props: {
    configuratorVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      darkModeActive: false,
      fontSizeScale: 100,
      buttonSize: "50px", // Ajuste do botão
      internalConfiguratorVisible: this.configuratorVisible,
    };
  },
  watch: {
    configuratorVisible(newVal) {
      this.internalConfiguratorVisible = newVal;
    },
    internalConfiguratorVisible(newVal) {
      this.$emit("update:configuratorVisible", newVal);
    },
  },
  methods: {
    switchTheme() {
      const themeLink = document.getElementById("theme-link");
      const themeMapping = {
        true: `themes/bootstrap4-dark-blue/theme.css`,
        false: `themes/bootstrap4-light-blue/theme.css`,
      };
      themeLink.href = themeMapping[this.darkModeActive];

      // Aplicar a classe dark-mode no elemento raiz
      document.body.classList.toggle("dark-mode", this.darkModeActive);
      this.saveSettings();
    },
    changeFontSize() {
      const scaleFactor = this.fontSizeScale / 100;
      document.documentElement.style.setProperty(
        "--font-size-scale",
        scaleFactor
      );
      this.saveSettings();
    },
    saveSettings() {
      localStorage.setItem("darkMode", this.darkModeActive);
      localStorage.setItem("fontSize", this.fontSizeScale);
    },
    loadSettings() {
      const darkMode = localStorage.getItem("darkMode") === "true";
      const fontSize = parseInt(localStorage.getItem("fontSize"), 10) || 100;

      this.darkModeActive = darkMode;
      this.fontSizeScale = fontSize;

      // Aplicar configurações carregadas
      const themeLink = document.getElementById("theme-link");
      const themeMapping = {
        true: `themes/bootstrap4-dark-blue/theme.css`,
        false: `themes/bootstrap4-light-blue/theme.css`,
      };
      themeLink.href = themeMapping[darkMode];
      document.body.classList.toggle("dark-mode", darkMode);
      const scaleFactor = fontSize / 100;
      document.documentElement.style.setProperty(
        "--font-size-scale",
        scaleFactor
      );
    },
  },
  created() {
    this.loadSettings();
  },
};
</script>

<style scoped>
.layout-config-wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 5;
}

.layout-config-content {
  padding: 1rem;
}
</style>
