import axios from "axios";
import { gerarUrl } from "../../utilities/utils/Pageable";
import AuthService from "../auth/auth_service.js";

export default class PersonService extends AuthService {

  constructor() {
    super("person")
  }

  searchPerson(queryParams) {
    queryParams = gerarUrl(queryParams);
    return axios({
      method: "get",
      url: this.api_url + "person?" + queryParams,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    }).then((res) => res.data);
  }

  findByCpfOrCredential(key) {
    return axios({
      method: "GET",
      url: this.api_url + "person/search?keyParam=" + key,
    }).then((res) => res.data);
  }

}
