<template>
  <div class="layout-footer">
    <img
      alt="Logo"
      src="../../../public/images/sespa/brasaoSespa.png"
      height="40"
      class="mr-2"
    />
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  methods: {
    footerImage() {
      return this.$appState.darkTheme
        ? "images/logo-white.svg"
        : "images/logo-dark.svg";
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>