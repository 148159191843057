<template>
  <div class="session-timer-container" v-if="timeRemaining > 0">
    <div class="session-text mb-1">Tempo de Sessão</div>
    <div class="timer-container">
      <i class="pi pi-clock timer-icon"></i>
      <div class="timer mb-1">
        <p>{{ formattedTime }}</p>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Token from "../../utilities/jwt/Token";

export default {
  data() {
    return {
      timeRemaining: 0,
      tokenExpirationTime: 0, // Armazenamos a expiração do token
      timer: null,
    };
  },
  computed: {
    // Formato o tempo restante como HH:MM:SS
    formattedTime() {
      const hours = Math.floor(this.timeRemaining / 3600);
      const minutes = Math.floor((this.timeRemaining % 3600) / 60);
      const seconds = this.timeRemaining % 60;

      return `${this.padTime(hours)}:${this.padTime(minutes)}:${this.padTime(
        seconds
      )}`;
    },
  },
  created() {
    this.startTokenTimer();
  },
  beforeUnmount() {
    // Limpeza do timer ao desmontar o componente
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    startTokenTimer() {
      const tokenManager = new Token();
      const token = tokenManager.token(); // Obtenho o token

      // Verifico se o token existe e não está expirado
      if (!token || tokenManager.checkExpiredToken()) {
        return; // Saio se não houver token válido
      }

      let decodedToken;
      try {
        decodedToken = tokenManager.jwtDecode(); // Decodifico o token
      } catch (error) {
        console.error("Erro ao decodificar o token:", error);
        return; // Saio se houver erro na decodificação
      }

      // Verifico se o token foi decodificado corretamente
      if (!decodedToken || !decodedToken.exp) {
        return; // Saio se não houver informações válidas
      }

      const currentTime = Math.floor(Date.now() / 1000);
      this.tokenExpirationTime = decodedToken.exp;

      this.timeRemaining = this.tokenExpirationTime - currentTime; // Calculo o tempo restante

      if (this.timeRemaining <= 0) {
        this.handleTokenExpiration(); // Trato a expiração do token
        return;
      }

      // Início do contador regressivo
      this.timer = setInterval(() => {
        const currentTime = Math.floor(Date.now() / 1000); // Obtenho o tempo real atual
        this.timeRemaining = this.tokenExpirationTime - currentTime; // Recalculo o tempo restante

        if (this.timeRemaining <= 0) {
          clearInterval(this.timer); // Limpo o timer
          this.handleTokenExpiration(); // Trato a expiração do token
        }
      }, 1000);
    },
    // Preencho o tempo com zeros à esquerda
    padTime(value) {
      return String(value).padStart(2, "0");
    },
    handleTokenExpiration() {
      this.$emit("tokenExpired"); // Emito evento de expiração do token
    },
  },
};
</script>
  
  
  <style scoped>
/* Centralizo o conteúdo */
.session-timer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Container para o timer e ícone */
.timer-container {
  display: flex;
  align-items: center; /* Alinho verticalmente */
}

/* Estilizo o ícone de relógio */
.timer-icon {
  font-size: var(--font-size-scale); /* Tamanho reduzido do ícone */
  color: #007ad9;
  margin-right: 10px; /* Espaço entre o ícone e o timer */
}

/* Estilizo o timer como um badge */
.timer {
  font-size: var(--font-size-scale); /* Tamanho reduzido do timer */
  font-weight: bold;
  letter-spacing: 2px; /* Espaçamento reduzido */
}

/* Estilizo o texto "Tempo de Sessão" */
.session-text {
  font-size: var(--font-size-scale); /* Tamanho reduzido do texto */
  font-weight: bold;
  margin-top: 5px; /* Margem reduzida */
}
</style>
  