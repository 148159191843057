import Person from "./person";
import { required, email, minLength } from "@vuelidate/validators";

export default class Users {
  constructor() {
    this.id = null;
    this.login = null;
    this.password = null;
    this.email = null;
    this.status = true;
    this.dtCreated = null;
    this.term = false;
    this.dtUpdate = new Date();
    this.person = new Person();
    this.dtAcceptTerm = null;
    this.group = [];

  }

  validationsCreate() {
    return {
      login: {
        required,
      },
      password: {
        required,
      },
      email: {
        required,
        email,
      },
    };
  }
  validations() {
    return {
      password: {
        required,
        minLength: minLength(6),
      },
      email: {
        required,
        email,
      },
      group: {
        required,
      },
    };
  }

}
